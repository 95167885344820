<template>
  <v-theme-provider :dark="dark">
    <div>
      <!--
      <base-img
        :src="require('@/assets/zero-logo-dark.svg')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      />
      -->

      <base-title
        size="body-1"
        space="4"
        :title="$t('landing-info.title')"
        weight="regular"
      />

      <base-body>
        <div v-html="$t('landing-info.text')" />
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        href="https://docs.udacapi.com"
      >
        {{ $t('landing-info.btn') }}
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({

    }),
  }
</script>
