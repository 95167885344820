/**
 * Store for BEND relates vuex
 */

// import axios from 'axios'
import Vue from 'vue'
export default {
  // https://www.drupal.org/project/jsonapi
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {
    calls: {
      organization: false,
      projects: false,
      webuser: false,
    },
    onlineProjects: [],
    organization: { allStaff: [], currentStaff: [{ roles: [] }], projects: [] },
    activeForm: {},
  },

  /* =================================================
  //                     MUTATIONS
  ================================================== */

  mutations: {
    // Reset to initial state
    reset (state) {
      state.calls.organization = false
      state.calls.projects = false
      state.calls.webuser = false
      state.organization = { allStaff: [], currentStaff: [] }
    },

    // project list from bend
    setOnlineProjects (state, payload) {
      // Vue.set(onlineProjects, 'name', payload.name)
      state.onlineProjects = { ...state.onlineProjects, name: payload.name }
      state.onlineProjects = payload
    },

    // setSelectedProject
    setSelectedProject (state, payload) {
      state.selectedProject = payload
      state.name = payload[0].name
    },

    // Packages and arguments
    setPackageArguments (state, prop) {
      state.packageArguments = prop
    },

    // Packages and arguments as named ojbect
    // TODO Remove setPackageArguments mutation, action... and merge it with this
    setPackArguments (state, prop) {
      state.packArguments = prop
    },

    // called from ocpu action [getPackage]
    setPackageData (state, payload) {
      // console.log('mutating ' + payload.pack)
      const sppack = state.selectedProject[0].packs[payload.pack]
      const oppack = state.onlineProjects[payload.pkey].packs[payload.pack]
      // console.log(state.name)
      // console.log(state.selectedProject[0].packs[payload.packname])
      Vue.set(sppack, 'data', payload.value)
      Vue.set(oppack, 'data', payload.value)
    },

    // Updates Sync data in Packages of onlineProjects and selectedProject
    setDsSyncData (state, payload) {
      const pack = 'udac' + payload.dsname
      // console.log(pack)
      if (payload.updateSelected) {
        state.selectedProject[0].packs[pack].syncLink = payload.link
        state.selectedProject[0].packs[pack].syncDate = payload.date
      }
      state.onlineProjects[payload.key].packs[pack].syncLink = payload.link
      state.onlineProjects[payload.key].packs[pack].syncDate = payload.date
    },

    // Updates Project sync data in onlineProjects and selectedProject
    setProjectSyncData (state, payload) {
      if (payload.updateSelected) {
        state.selectedProject[0].syncDate = payload.date
        state.selectedProject[0].submissions = payload.submission
      }
      state.onlineProjects[payload.key].syncDate = payload.date
      state.onlineProjects[payload.key].submissions = payload.submission
    },

    setFormSyncData (state, payload) {
      // console.log(payload)
      const f = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      f.syncDate = payload.date
      f.submissions = payload.submission
    },

    setSyncMode (state, payload) {
      const sppack = state.selectedProject[0]
      const oppack = state.onlineProjects[payload.pkey]
      Vue.set(sppack, 'syncMode', payload.mode)
      Vue.set(oppack, 'syncMode', payload.mode)
    },

    // Update bend calls. Change to true if the call is done.
    setCalls (state, payload) {
      state.calls = Object.assign({}, state.calls, payload)
    },

    // Organization from bend
    setOrganization (state, payload) {
      // Called from AccountSettings, getOrganization()
      // console.log(payload)
      state.organization = payload
    },

    // Organization from bend
    delOrganization (state, payload) {
      // Called from organization.js, delOrganization()
      for (let i = 0; i < payload.length; i++) {
        delete state.organization[payload[i]]
      }
    },

    // Organization from bend
    setOrgNewProject (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.newproject = payload
    },

    // Set organization's projects
    setOrgProjects (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.projects = payload
    },

    // Set organization's projects
    setOrgStaff (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.staff = payload
    },

    // Set form of Selected Project
    setProjectForms (state, payload) {
      // Called from FormDraft, addNewForm()
      // console.log(payload.pindex)
      state.organization.projects[payload.pindex].forms = payload.forms
    },

    // Set form of Selected Project
    setOrganizationProjects (state, payload) {
      // Called from projectSettings, deleteProject()
      state.organization.projects = payload.projects
    },

    // Set form of Selected Project
    setProjectRoles (state, payload) {
      // Called from projectSettings, deleteProject()
      state.organization[payload.ix.pindex].roles = payload.roles
    },

    // Adds value into organization, called from mixin webusers.js
    addToOrganization (state, payload) {
      state.organization = Object.assign({}, state.organization, payload)
    },

    // Update user type, called from AccountSettings
    updateUserType (state, user) {
      // console.log(user)
      state.organization.allStaff[user.index].type = user.type
    },

    // Update Experimental status e.g. tester = prop
    updateTesterState (state, prop) {
      // console.log(user)
      state.organization.tester = prop
    },

    // Updates ODK Form data in onlineProjects and selectedProject
    saveOdkForm: (state, payload) => {
      // console.log(payload)
      const p = state.selectedProject[0]
      const key = p.key
      // update selectedProject
      p.odkmodels = payload[0]
      p.odkquestions = payload[1]
      p.odklanguages = payload[2]
      p.odksettings = payload[3]
      // update in onlineProjects
      state.onlineProjects[key].odkmodels = payload[0]
      state.onlineProjects[key].odkquestions = payload[1]
      state.onlineProjects[key].odklanguages = payload[2]
      state.onlineProjects[key].odksettings = payload[3]
    },

    // Updates ODK Form data in onlineProjects and selectedProject
    updateOdkForm: (state, payload) => {
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odkquestions = payload.questions
      form.odkmodels = payload.models
      form.odklangs = payload.languages
      form.odksettings = payload.formsettings
    },

    // Updates ODK Form settings
    updateOdkSettings: (state, payload) => {
      // console.log(payload)
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odksettings = payload.settings
    },

    updateOdkFormPhase: (state, payload) => {
      // console.log(payload)
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odksettings.phase = payload.phase
      if ('attachment' in payload) {
        form.odksettings.attachment = payload.attachment
      }
      if ('enketoid' in payload) {
        form.odksettings.enketoid = payload.enketoid
      }
    },

    // Updates Data Filters data in onlineProjects and selectedProject
    saveDataFilter: (state, payload) => {
      const p = state.selectedProject[0]
      const key = p.key
      // update selectedProject
      p.datafilter = payload
      // update in onlineProjects
      state.onlineProjects[key].datafilter = payload
    },

    // SELECTED PROJECT Related
    selectedProject (state, projectList) {
      state.selectedProject = projectList
    },

    setProjectListPackage (state, [projectPackage, key]) {
      state.projectList[key].udaPackages = projectPackage
    },

    // setPackages (state, packageList) {
    //   state.packageList = packageList
    // },

    setProjectPackage (state, projectPackage) {
      state.selectedProject[0].udaPackages = projectPackage
    },

    udaPackStatus (state, [packageName, status]) {
      // console.log('udaPackStatus ga keldi')
      // console.log(packageName)
      state.udaPackStatus[[packageName]] = status
    },
  },

  /* =================================================
  //                     GETTERS
  ================================================== */
  getters: {
    // Check and change I'm using .packs instead of .udsPacks
    getPackByName: state => packName => {
      return state.selectedProject.udaPacks.find(
        pack => pack.name === packName,
      )
    },

    // Check and change I'm using .packs instead of .udsPacks
    getsyncDate: state => packName => {
      return state.selectedProject.packs[packName].syncDate
    },
  },

  /* =================================================
  //                     ACTIONS
  ================================================== */
  actions: {
    // Reset state when loged out
    reset ({ commit }) {
      commit('reset')
    },

    // Get all online projects from bend and
    // commit setOnlineProjects; setSelectedProject
    // Called with async key from [initialSetup]
    getOnlineProjects ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        // console.log('getOnlineProjects da')
        this._vm.$httpBend
          .get(
            '/jsonapi/node/projects?include=field_uda_packages&sort=-field_dates.value',
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            },
          )
          .then(response => {
            const mainPart = response.data.data
            const packagePart = response.data.included
            let incStart = 0
            let incLen = 0
            let incStop = 0
            const projectList = []
            for (let k = 0, len = mainPart.length; k < len; k++) {
              const p = {}
              p.key = k
              p.id = mainPart[k].id
              p.nid = mainPart[k].attributes.drupal_internal__nid
              p.name = mainPart[k].attributes.title
              p.body = mainPart[k].attributes.body.value
              p.startDate = mainPart[k].attributes.field_dates.value
              p.syncDate = mainPart[k].attributes.field_date
              p.formid = mainPart[k].attributes.field_form_id
              p.server = mainPart[k].attributes.field_url.uri
              p.pid = mainPart[k].attributes.field_project_id
              p.totalsurvey = mainPart[k].attributes.field_total_survey
              p.days = mainPart[k].attributes.field_integer
              p.sumcolumn = mainPart[k].attributes.field_text
              p.sumvalue = mainPart[k].attributes.field_value
              p.lang = mainPart[k].attributes.field_lang
              p.submissions = mainPart[k].attributes.field_submissions
              p.fdatalink = ''
              p.odkmodels = JSON.parse(
                mainPart[k].attributes.field_odkmodels,
              )
              p.odkquestions = JSON.parse(
                mainPart[k].attributes.field_odkquestions,
              )
              p.odklangs = JSON.parse(
                mainPart[k].attributes.field_odklangs,
              )
              p.odksettings = JSON.parse(
                mainPart[k].attributes.field_odksettings,
              )

              // p['syncMode'] = '600000'

              const objPacks = []
              const pack = {}
              // Get all packages included to this project
              incLen = mainPart[k].relationships.field_uda_packages.data.length
              incStop = incLen + incStop
              incStart = incStop - incLen
              for (let i = incStart; i < incStop; i++) {
                const oi = {}
                let packName = ''
                oi.id = packagePart[i].id
                oi.nid = packagePart[i].attributes.drupal_internal__nid
                oi.name = packagePart[i].attributes.title
                oi.syncDate = packagePart[i].attributes.field_date
                oi.syncLink = packagePart[i].attributes.field_link
                packName = packagePart[i].attributes.title

                objPacks.push(oi)
                pack[packName] = oi
              }
              p.udaPacks = Object.assign([], p, objPacks)
              p.packs = Object.assign({}, pack)
              projectList.push(p)
            }
            commit('setOnlineProjects', projectList)
            commit('setSelectedProject', [projectList[0]])
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    projectSelected ({ commit, dispatch }, nextProject) {
      // console.log('In projectSelected')
      const pkey = nextProject[0].key
      return dispatch(
        { type: 'ocpu/runOcpuCode', pkey: pkey },
        { root: true },
      ).then(() => {
        commit('setSelectedProject', nextProject)
        const formid = nextProject[0].formid
        // console.log(formid)
        // dispatch('ocpu/getTableList', formid, { root: true })
        dispatch('ocpu/getTableListRules', formid, { root: true })
      })

      /* if (state.selectedProject[0].udaPacks.length !== 0) {
        // console.log('udaPacks.length !== 0')
        dispatch('udaPack', selectedProject)
      } else {
        // console.log('udaPacks.length !== 0 else')
        return dispatch('getPackageList', selectedProject[0].nid).then(() => {
          dispatch('udaPack', selectedProject)
        })
      }  */
    },

    // Write dataset sync data to  Bend's Package Details
    // Called from ocpu after success action [genDataset]
    // OLD CODE
    setPackSyncData ({ dispatch, commit, rootState }, props) {
      // console.log('setPackSyncData da')
      const p = rootState.bend.selectedProject[0]
      const key = p.key
      const d = new Date()
      const syncDate = d.toISOString().slice(0, 19) + '+00:00'
      const id = props[0]
      const link = props[1]
      const dsname = props[2]
      const date = syncDate
      commit('setDsSyncData', {
        key: key,
        link: link,
        date: syncDate,
        dsname: dsname,
        updateSelected: true,
      })
      // console.log(" here in syncData " + key);

      // console.log(id)
      // console.log(link)
      // console.log(date)
      const patchBody = {
        data: {
          type: 'node--package_details',
          id: `${id}`,
          attributes: { field_link: `${link}`, field_date: `${date}` },
        },
      }
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .patch('/jsonapi/node/package_details/' + id, patchBody, {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            // console.log('setPackSyncData error')
            // console.log(error)
            reject(error)
          })
      })
    },

    // Write dataset sync data to Project node
    // Called from Active Forms page after success action [udacUpdate]
    setFormSyncData ({ commit, rootState, state }, props) {
      // console.log(' here in syncData ' + props)
      const ocpuPack = rootState.ocpu.ocpuPack
      const id = props[0]
      const key = props[1]
      const date = props[2]
      const sserver = props[3]
      const patchBody = {
        data: {
          type: 'node--form',
          id: `${id}`,
          attributes: {
            field_date: `${date}`,
            field_submissions: `${sserver}`,
          },
        },
      }
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              commit('setFormSyncData', {
                ix: key,
                date: date,
                submission: sserver,
              })
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    setUdaPackStatus ({ commit }, [packageName, status]) {
      // console.log('sending pack status update')
      // console.log(packageName)
      commit('udaPackStatus', [packageName, status])
    },

    // Write odkForm to  bend's odk text fields
    // Called from ODK Form page when run save action
    updateOdkForm ({ commit, rootState, state }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
      // STEP 1. Post new ODK Form
      const id = props.formjsonid
      const odk = {
        odkquestions: props.questions,
        odkmodels: props.models,
        odklangs: props.languages,
      }
      const patchBody = {
        data: {
          type: 'node--form',
          id: id,
          attributes: {
            field_form_settings: `${JSON.stringify(props.formsettings)}`,
            field_odk: `${JSON.stringify(odk)}`,
          },
        },
      }
      commit('updateOdkForm', props)
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Write odkForm to  bend's odk text fields
    odkFormPhase ({ commit, rootState, state }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
      const id = props.formjsonid
      // STEP 1. Change setting's phase.
      commit('updateOdkFormPhase', props)
      // console.log(JSON.stringify(state.organization.projects[props.ix.pindex].forms[props.ix.findex].odksettings))
      // console.log(props.formjsonid)

      // STEP 2. Post new ODK Form
      const patchBody = {
        data: {
          type: 'node--form',
          id: id,
          attributes: {
            field_form_settings: `${JSON.stringify(state.organization.projects[props.ix.pindex].forms[props.ix.findex].odksettings)}`,
            field_submissions: 0,
          },
        },
      }
      // console.log(patchBody)
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
            // return reject(error.message)
          })
      })
    },

    /**
     * Bend PATCH and DELETE API calls via OCPU
    */

    patchNode ({ rootState }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
    // console.log(props)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: props.path,
            patchBody: props.patchBody,
            orgid: props.orgid,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
