import Vue from 'vue'
// Tesing pinia state - management
import { createPinia, PiniaVuePlugin } from 'pinia'
import axios from 'axios'
import apiBend from '@/api/api-bend'
import apiOcpu from '@/api/api-ocpu'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import Router from 'vue-router'
import i18n from './i18n'
import VueTour from 'vue-tour'
import L from 'leaflet'
import App from './App.vue'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

// Tesing pinia state - management
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(Router) // <--- MUST BE AFTER Vue.use(PiniaVuePlugin)

Vue.config.productionTip = false

delete L.Icon.Default.prototype._getIconUrl

// Fixing missing marker: https://stackoverflow.com/a/50866199
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

new Vue({
  render: h => h(App),
  pinia,
  router,
  store,
  vuetify,
  axios,
  i18n,
}).$mount('#app')

Vue.prototype.$httpOcpu = apiOcpu
Vue.prototype.$httpBend = apiBend
