// https://vuex.vuejs.org/en/state.html

export default {
  initialData: false,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  // TODO using these in the state of ocpu. consider to delete following two
  // dataFiles: ['stata', 'spss', 'csv', 'excel'],
  // dataSets: { stata: {}, spss: {}, csv: {}, excel: {} },
  baseLinkOcpu: 'https://ocpu.udaconsulting.com/ocpu',
  barImage: require('@/assets/sidebar-1.jpg'),
  //  'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
  drawer: null,
  // projectPostParams: {
  //   _links: {
  //     type: { href: 'https://bend.udacapi.org/rest/type/node/projects' },
  //   },
  //   type: [{ target_id: 'projects' }],
  //   title: [{ value: 'Updated by REST API' }],
  // },
  // packageList: [],
  // selectedTable: [],
  // questions: [],
  // selectedColumns: [],
  settingBlockFull: {},
  settingMonitoring: {},
}
