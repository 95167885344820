<template>
  <section class="mb-10 text-center">
    <h1
      class="font-weight-light mb-2 headline"
      v-text="`${organization} ${heading}`"
    />

    <span
      class="font-weight-light subtitle-1"
    >
      {{ $t('oth.checkout-start') }}
      <a
        :href="`https://docs.udacapi.com/${link}`"
        rel="noopener"
        target="_blank"
        class="secondary--text"
        style="text-decoration:none;"
      >
        {{ $t('oth.checkout-mid') }}
      </a>
      {{ $t('oth.checkout-end') }}
      {{ $t('oth.checkout-tour') }}
      <v-btn
        small
        icon
        dark
        @click="startTour"
      >
        <v-icon
          color="secondary"
        >
          mdi-school
        </v-icon>
      </v-btn>
    </span>
  </section>
</template>

<script>
  export default {
    name: 'VComponent',

    props: {
      organization: {
        type: String,
        default: 'UDA Consulting',
      },
      heading: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '',
      },
    },

    methods: {
      startTour () {
        // console.log('ahaf=')
        this.$parent.$tours.myTour.start()
      },
    },
  }
</script>
