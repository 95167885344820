<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <!-- <p class="font-weight-bold mt-4 mr-1">
        {{ $i18n.locale }}
      </p>
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          mdi-translate
        </v-icon>
      </v-btn> -->
      <v-btn
        class="mx-3"
        icon
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar
          size="24"
        >
          <img
            :src="require(`@/assets/flags/${$i18n.locale}.png`)"
            alt="Language"
          >
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(language, index) in langs"
        :key="index"
        selectable
        link
      >
        <v-list-item-title
          @click="setLocale(language.code)"
        >
          {{ language.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'LangSwitch',

    props: { },

    data: () => ({
      // download flags: https://www.countryflags.com/ and paste into src/assets/flags
      langs: [
        { code: 'en', label: 'English' },
        { code: 'tr', label: 'Türkçe' },
        { code: 'pt', label: 'Português' },
        // { code: 'ar', label: 'عربي' },
        { code: 'ru', label: 'Русский' },
      ],

    }),
    methods: {
      setLocale (locale) {
        if (this.$i18n.locale === locale) {
          return
        }
        this.$i18n.locale = locale
        this.$router.push({
          query: { locale: locale },
        })
      },
    },

  }
</script>

<style>
.v-text-field .v-select__slot .v-select__selection--comma { min-width: min-content }
</style>
