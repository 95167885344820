<template>
  <base-material-alert
    color="warning"
    dark
    dismissible
    icon="mdi-bell"
  >
    {{ $t('monitoring.empty-note') }}
    <router-link
      v-slot="{ href, navigate }"
      :to="`/${link}`"
    >
      <a
        :href="href"
        @click="navigate"
      >{{ text }} {{ $t('monitoring.add-some') }}</a>
    </router-link>
  </base-material-alert>
</template>

<script>
  export default {
    name: 'MonitorNotification',

    props: {
      blockType: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: undefined,
      },
      link: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
    },
  }
</script>
