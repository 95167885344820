// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import auth from './modules/auth'
import bend from './modules/bend'
import main from './modules/main'
import ocpu from './modules/ocpu'
import odkform from './modules/odkform'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules: {
    auth,
    bend,
    main,
    ocpu,
    odkform,
  },
  actions,
  getters,
  mutations,
  state,
})

export default store
