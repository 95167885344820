import { defineStore } from 'pinia'

export const useSubscriptionStore = defineStore('SubscriptionStore', {

  //     /* -------------------------------------------------
  //     //                      STATE
  //     ================================================== */
  state: () => ({
    sStart: '',
    sDuration: '',
    sType: 'standard',
    price: [
      {
        standard: 79,
        professional: 199,
        enterprise: 699,
        payment: 'yearly',
      },
      {
        standard: 99,
        professional: 249,
        enterprise: 849,
        payment: 'monthly',
      },
    ],
    subscprice: {
      enterprise: {
        eur: { yearly: [699], monthly: [849] },
        try: { yearly: [12999], monthly: [15999] },
        usd: { yearly: [699], monthly: [849] },
      },
      professional: {
        eur: { yearly: [199], monthly: [249] },
        try: { yearly: [3699], monthly: [4699] },
        usd: { yearly: [199], monthly: [249] },
      },
      standard: {
        eur: { yearly: [1], monthly: [99] },
        try: { yearly: [79], monthly: [1] },
        usd: { yearly: [1499], monthly: [1849] },
      },
    },
    features: {
      standard: { projects: '2', subs: '20000' },
      professional: { projects: '5', subs: '50000' },
      enterprise: { projects: '20+', subs: 'landing-compare.unlimited' },
    },
    pricingType: 0,
    mySubscription: [
      {
        code: 'standard',
        start: (new Date(new Date().setDate(new Date().getDate() - 1))).toLocaleDateString('en-CA'),
        end: (new Date()).toLocaleDateString('en-CA'),
        amount: 0,
        currency: '840',
        title: 'landing-compare.standard',
        type: 'trial',
        orderid: '20221110010101001',
      },
    ],
    aSub: {},
    calls: { price: false, subscprice: false, mySubsc: false, orgsubsc: false },
  }),

  //     /* -------------------------------------------------
  //     //                     GETTERS
  //     ================================================== */
  getters: {
    paymentmode () {
      return this.pricingType === 0 ? 'yearly' : 'monthly'
    },
    pricing () {
      const myprice = [
        {
          code: 'standard',
          best: false,
          heading: 'landing-compare.standard',
          icon: 'mdi-home',
          title: this.subscprice.standard.usd[this.paymentmode][0] + '/mo',
          projects: '2',
          forms: 'landing-compare.unlimited',
          text: 'landing-plans.standard-text',
        },
        {
          code: 'professional',
          best: true,
          heading: 'landing-compare.pro',
          icon: 'mdi-domain',
          title: `${this.subscprice.professional.usd[this.paymentmode][0]}/mo`,
          projects: '5',
          forms: 'landing-compare.unlimited',
          text: 'landing-plans.pro-text',
        },
        {
          code: 'enterprise',
          best: false,
          heading: 'landing-compare.enterprise',
          icon: 'mdi-bank',
          title: `${this.subscprice.enterprise.usd[this.paymentmode][0]}/mo`,
          projects: '20',
          forms: 'landing-compare.unlimited',
          // text: 'oth.plan-note',
          text: 'landing-plans.pro-text',
        },
      ]
      return myprice
    },

    activeSubsc () {
      const mySubscActiveRow = this.mySubscription.length - 1
      return this.mySubscription[mySubscActiveRow]
    },
    subscDayLeft () {
      const mySubscActiveRow = this.mySubscription.length - 1
      const leftEpoch = new Date(this.mySubscription[mySubscActiveRow].end) - new Date()
      // convert to date and change -0 to 0 usind parseInt()
      // When x is -0, Math.round(x) returns -0, while Math.floor() returns 0
      const asDays = parseInt(Math.floor(leftEpoch / (1000 * 60 * 60 * 24)))
      return asDays
    },
  },

  //     /* -------------------------------------------------
  //     //                     ACTIONS
  //     ================================================== */
  actions: {
    increment () {
    },

    // // set selected subscription
    // setActiveSubsc (subscription) {
    //   this.aSub = subscription
    // },
  },
})
