// https://vuex.vuejs.org/en/mutations.html

export default {
  // THEME Related
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },

  SET_DRAWER (state, payload) {
    state.drawer = payload
  },

  SET_SCRIM (state, payload) {
    state.barColor = payload
  },

  setInitialData (state, payload) {
    // console.log('initialData true')
    state.initialData = payload
  },

  // tableMonitoring (state, payload) {
  //   state.tableMonitoring = Object.assign({}, state.tableMonitoring, payload)
  // },

  // Utilities
  /*
  progressPieStatus (state, status) {
    // one of these: noData, loading, ready
    state.udaProgressPieStatus = status
  },
  */

  // gg
  // udaPackStatus (state, [packageName, status]) {
  //   // console.log('udaPackStatus ga keldi')
  //   // console.log(packageName)
  //   state.udaPackStatus[[packageName]] = status
  // },

  // settingSetBlockFull (state, taxonomy) {
  //   state.settingBlockFull = taxonomy
  // },

  // settingSetBlockType (state, parent) {
  //   state.settingBlockType = parent
  // },

  // settingSetMonitoring (state, mSettings) {
  //   state.settingMonitoring = mSettings
  // },

  // ALL PROJECTS Related
  // ggg
  // setProjects (state, projectList) {
  //   state.bend.onlineProjects = projectList
  // },

  // ggg
  // setPackages (state, packageList) {
  //   state.packageList = packageList
  // },

  // ggg
  // setProjectListPackage (state, [projectPackage, key]) {
  //   state.bend.onlineProjects[key].udaPackages = projectPackage
  // },

  // setProjectListLocation (state, [locations, key]) {
  //   state.bend.onlineProjects[key].udaLocation = locations
  // },

  // // should be changed as I have setDsSyncDate
  // // was: if (updateSelected) state.selectedProject[0].syncDate = syncDate
  // setProjectSyncDate (state, [key, syncDate, updateSelected]) {
  //   if (updateSelected) state.bend.selectedProject[0].sync.date = syncDate
  //   state.bend.onlineProjects[key].sync.date = syncDate
  // },

  // setDsSyncDate (state, [key, syncDate, dsname, updateSelected]) {
  //   if (updateSelected) { state.bend.selectedProject[0].sync[`${dsname}` + 'Date'] = syncDate }
  //   state.bend.onlineProjects[key].sync[`${dsname}` + 'Date'] = syncDate
  // },

  // setSubmissions (state, [key, sserver, updateSelected]) {
  //   if (updateSelected) state.bend.selectedProject[0].submissions = sserver
  //   state.bend.onlineProjects[key].submissions = sserver
  // },

  // // should be deleted as I have setDsLinks in ocpu
  // setProjectSyncLink (state, [key, fileName, uri, updateSelected]) {
  //   if (updateSelected) state.bend.selectedProject[0].sync[fileName] = uri
  //   state.bend.onlineProjects[key].sync[fileName] = uri
  // },

  // // Maybe I'll not use this
  // setCrossTabData (state, [key, crossTab, updateSelected]) {
  //   if (updateSelected) state.bend.selectedProject[0].crossTab = crossTab
  //   state.bend.onlineProjects[key].crossTab = crossTab
  // },

  // setTableData (state, [key, table, dataSet, updateSelected]) {
  //   if (updateSelected) state.bend.selectedProject[0][table] = dataSet
  //   state.bend.onlineProjects[key][table] = dataSet
  // },

  // // SELECTED PROJECT Related
  // // ggg
  // selectedProject (state, projectList) {
  //   state.bend.selectedProject = projectList
  // },

  // ggg
  // setProjectPackage (state, projectPackage) {
  //   state.bend.selectedProject[0].udaPackages = projectPackage
  // },
  /*
  gotData (state, responce) {
    // console.log(' here in gotData mutation')
    state.selectedProject[0].syncFolder = responce
  },

  gotOtherData (state, responce) {
    state.selectedProject[0].syncDate = responce
  },
  */

  // setProjectLocation (state, locations) {
  //   state.bend.selectedProject[0].udaLocation = locations
  // },

}
