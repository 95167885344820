import axios from 'axios'
import store from '../store'

export function getBendUrl () {
  return store.state.auth.baseUrl
}

const bendDomain = getBendUrl()
const bbaseURL = `${bendDomain}`
const token = localStorage.getItem('token')

const bendInstance = axios.create({
  baseURL: bbaseURL,
  timeout: 100000,

  headers: {
    'Content-Type': 'application/hal+json',
    Authorization: 'Bearer ' + token,
    // 'Authorization': `Bearer ${state.token}`, // This is also not working
    Accept: 'application/hal+json',
  },

})
export default bendInstance

/*
// Get value from store
// suggestion https://forum.vuejs.org/t/how-to-access-vuex-store-from-a-js-module/10124/7
import store from '../store'
export function getToken () {
  return store.state.token
}
// const token = getToken()
*/

/*
//https://nick-basile.com/blog/post/how-to-make-api-calls-with-vuex
export default {
    get (url, request) {
        return Vue.http.get(url, request)
            .then((response) => Promise.resolve(response.body.data))
            .catch((error) => Promise.reject(error))
    },
    post (url, request) {
        return Vue.http.post(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    patch (url, request) {
        return Vue.http.patch(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    delete (url, request) {
        return Vue.http.delete(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
}
*/
