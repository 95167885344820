import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ar from 'vuetify/lib/locale/ar'
import en from 'vuetify/lib/locale/en'
import pt from 'vuetify/lib/locale/pt'
import ru from 'vuetify/lib/locale/ru'
import tr from 'vuetify/lib/locale/tr'

Vue.use(VueI18n)

const messages = {
  tr: {
    ...require('@/locales/tr.json'),
    $vuetify: tr,
  },
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  ru: {
    ...require('@/locales/ru.json'),
    $vuetify: ru,
  },
  pt: {
    ...require('@/locales/pt.json'),
    $vuetify: pt,
  },
  ar: {
    ...require('@/locales/ar.json'),
    $vuetify: ar,
  },
}

export default new VueI18n({
  // // Turkish by default
  // locale: 'tr',
  // fallbackLocale: 'tr',

  // English by default
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE || 'en',

  messages,
})
