<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="$t('address.keepintouchtitle')"
        pace="6"
      >
        {{ $t('address.keepintouchtext') }}
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

  }
</script>
