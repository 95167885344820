<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <base-text-field
      v-model="name"
      label="Name"
    />

    <base-text-field
      v-model="email"
      label="Email"
    />

    <base-text-field
      v-model="subject"
      label="Subject"
    />

    <base-textarea
      v-model="body"
      class="mb-6"
      label="Your Need & Description"
    />

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      :href="mailto"
      outlined
      target="_blank"
    >
      {{ $t('address.btn-sendmessage') }}
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
    data: () => ({
      name: '',
      email: '',
      subject: 'UDACAPI question',
      body: '',
    }),
    computed: {
      mailto () {
        return 'mailto:info@udaconsulting.com?subject=' + this.subject + '&body=' + this.body
      },
    },
  }
</script>
