<template>
  <section class="mb-10">
    <h1
      class="font-weight-light mb-2 display-2"
      v-text="`Project: ${projectname}`"
    />

    <span
      class="font-weight-light subtitle-1"
    >
      (Total submissions: {{ submissions }})
    </span>
  </section>
</template>

<script>
  export default {
    name: 'ProjectName',

    props: {
      projectname: {
        type: String,
        default: '',
      },
      started: {
        type: String,
        default: '',
      },
      submissions: {
        type: Number,
        default: 0,
      },
    },
  }
</script>
