/**
 * Store for ODK Form relates states
 */

// import findPath from '@/views/odkform/components/findPath'
export default {
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {

    avalue: {},
    // aschema: {
    //   type: 'object',
    //   properties: {
    //     name: {
    //       type: 'string',
    //     },
    //   },
    //   globalConfig: {},
    // },
    dialogAddCode: {
      caller: '',
      value: false,
    },
    dialogUplChoice: {
      caller: '',
      value: false,
    },
    dialogImportCh: false,
    price: [
      { standard: 79, professional: 199, enterprise: 'landing-compare.contact-us' },
      { standard: 99, professional: 249, enterprise: 'landing-compare.contact-us' },
    ],

    // choosenel: '',
  },

  /* =================================================
  //                    GETTERS
  ================================================== */
  getters: {},

  /* =================================================
  //                    MUTATIONS
  ================================================== */

  mutations: {

    setAValue: (state, payload) => {
      // console.log(payload)
      state.avalue = payload
    },

    // setASchema: (state, payload) => {
    //   // console.log(payload)
    //   state.aschema = payload
    // },

    dialogAddCode: (state, payload) => {
      // console.log(payload)
      state.dialogAddCode = payload
    },

    dialogUplChoice: (state, payload) => {
      // console.log(payload)
      state.dialogUplChoice = payload
    },

    dialogImportCh: (state, payload) => {
      state.dialogImportCh = payload
    },

    // Update validation of the model
    modelValidation: (state, payload) => {
      // traverse function to mutate model's validation
      function runTraverse (target) {
        // loop target questions
        target.forEach(function (arrayItem) {
          // if it has the same internalKey mutate it's [valid] property
          if (arrayItem.internalKey === payload[0]) {
            arrayItem.valid = payload[1]
          }
          // if it has children property it's a group: run the function again
          if ('children' in arrayItem) {
            runTraverse(arrayItem.children)
          }
        })
      }
      // run traverse function
      runTraverse(state.questions)
    },

  },

  /* =================================================
  //                    ACTIONS
  ================================================== */

  actions: {

    // Model validation action copy validation state from model into questions
    modelValidation: ({ commit, state }, payload) => {
      let groupInvalids = 0
      let isGroup = false
      let valid = false
      // traverse function if question is a group
      function runTraverseGroup (target) {
        // loop target model
        target.forEach(function (arrayItem) {
          if (arrayItem.valid === false) {
            // count all invalid models in the group
            groupInvalids = groupInvalids + 1
          }
          if ('children' in arrayItem) {
            runTraverseGroup(arrayItem.children)
          }
        })
      }

      // group validation should based on each model in this group
      // traverse function model for groups
      function runTraverse (target) {
        // loop target model
        target.forEach(function (arrayItem) {
          // if it has the same internalKey mutate it's valid property
          if (arrayItem.internalKey === payload[0] & 'children' in arrayItem) {
            isGroup = true
            // run function for all children if it has any nested
            if ('children' in arrayItem) {
              runTraverseGroup(arrayItem.children)
            }
          }
          if ('children' in arrayItem) {
            runTraverse(arrayItem.children)
          }
        })
      }

      // run traverse function
      runTraverse(state.questions)

      // prepare valid property to commet mutation
      if (isGroup) {
        valid = groupInvalids === 0
      } else {
        valid = payload[1]
      }
      // validation of single model
      commit('modelValidation', [payload[0], valid])
    },

  },
}
